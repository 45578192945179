import React, { FC } from 'react';

import { AccountInfoProps } from './AccountInfo.props';

import AccountInfoView from './AccountInfo.view';

const AccountInfoContainer: FC<AccountInfoProps> = (props) => {
  return <AccountInfoView {...props} />;
};

export default AccountInfoContainer;
