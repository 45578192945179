import React, { useEffect, useRef, useState } from 'react';

import {
  MarkOrderShippedPayload,
  MarkOrderShippedProps,
} from './MarkOrderShipped.props';

import MarkOrderShippedView from './MarkOrderShipped.view';
import { ToastError } from 'utils/toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toApiPayloadDateFormat } from 'utils/date';
import { useParams } from 'react-router-dom';
import { useShipOrderDetail } from 'hooks/useShipOrderDetail';
import { markOrderShipped } from 'services/shipOrders';

const MarkOrderShippedContainer = (props: MarkOrderShippedProps) => {
  const queryClient = useQueryClient();

  let { id } = useParams();
  const successModalRef = useRef<HTMLDialogElement>(null);
  const { shipOrderDetail } = useShipOrderDetail(id!);
  const [initialValues, setInitialValues] = useState<MarkOrderShippedPayload>();

  useEffect(() => {
    if (shipOrderDetail) {
      if (typeof shipOrderDetail.isReturnable === 'string')
        shipOrderDetail.isReturnable = shipOrderDetail.isReturnable === 'true';
      setInitialValues({
        contact: shipOrderDetail.contact || {
          contactName: '',
          phoneNumber: '',
        },
        accountInfo: shipOrderDetail.accountInfo,
        accountNumber: shipOrderDetail.accountNumber,
        address: shipOrderDetail.address,
        package: shipOrderDetail.package,
        client: shipOrderDetail.client?._id,
        arrivalDate: toApiPayloadDateFormat(shipOrderDetail.arrivalDate),
        booth: shipOrderDetail.booth,
        event: shipOrderDetail.event,
        orderStatus: shipOrderDetail.orderStatus,
        isClientBillToAccount: shipOrderDetail.isClientBillToAccount,
        notes: shipOrderDetail.notes,
        orderDetail: shipOrderDetail.orderDetail,
        pickupDate: toApiPayloadDateFormat(shipOrderDetail.pickupDate),
        prepByDate: toApiPayloadDateFormat(shipOrderDetail.prepByDate),
        projectManager: shipOrderDetail.projectManager?._id,
        returnDate: toApiPayloadDateFormat(shipOrderDetail.returnDate),
        shipDate: toApiPayloadDateFormat(shipOrderDetail.shipDate),
        recipient: shipOrderDetail.recipient ?? {
          name: '',
          email: '',
          phone: '',
        },
        advanceWarehouseDate:
          shipOrderDetail.advanceWarehouseDate?.startDate ||
          shipOrderDetail.advanceWarehouseDate?.endDate
            ? {
                startDate: shipOrderDetail.advanceWarehouseDate?.startDate
                  ? toApiPayloadDateFormat(
                      shipOrderDetail.advanceWarehouseDate?.startDate,
                    )
                  : null,
                endDate: shipOrderDetail.advanceWarehouseDate?.endDate
                  ? toApiPayloadDateFormat(
                      shipOrderDetail.advanceWarehouseDate?.endDate,
                    )
                  : null,
              }
            : null,
        transactionNumber: shipOrderDetail.transactionNumber || '',
        shippingPreference: shipOrderDetail.shippingPreference,
        isReturnable: shipOrderDetail.isReturnable,
        warehouse: process!.env!.REACT_APP_WAREHOUSE_ID
          ? process!.env!.REACT_APP_WAREHOUSE_ID
          : '', // TODO: This should not be hardcoded. Read from API once BE logic is in place
      });
    }
  }, [shipOrderDetail]);

  const { mutate, isPending } = useMutation({
    mutationFn: markOrderShipped,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['FETCH-SHIP-ORDER'],
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: ['FETCH-SHIP-ORDER-DETAILS', id],
        refetchType: 'all',
      });
      localStorage.removeItem('orderItems');
      successModalRef.current?.showModal();
    },

    onError: (e: any) => {
      const errorMessage =
        e?.response?.data?.error?.message || 'Order Processing failed';
      ToastError({ errorMessage });
    },
  });

  const submitForm = async () => {
    id && mutate({ id });
  };

  return (
    <MarkOrderShippedView
      submitForm={submitForm}
      successModalRef={successModalRef}
      id={id!}
      initialValues={initialValues!}
      isPending={isPending}
    />
  );
};
export default MarkOrderShippedContainer;
