import React, { FC } from 'react';
import { Field, useField } from 'formik';
import { FormikRadioInputProps } from './FormikRadioInput.props';
import FormikErrorMessage from 'components/core/FormikErrorMessage';
const FormikRadioInputView: FC<FormikRadioInputProps> = ({
  items,
  name,
  onClick,
}) => {
  const [data, meta, helpers] = useField(name);
  return (
    <React.Fragment>
      <div className="flex gap-4">
        {items.map((e, index) => (
          <div key={index} className="flex gap-2 items-center">
            <Field type="radio" name={name} value={e.value}>
              {({ field }: any) => (
                <>
                  <input
                    {...field}
                    onClick={(c) => {
                      onClick && onClick(e.value);
                    }}
                    id={`${name}_${e.value}`}
                    type="radio"
                    checked={data.value.toString() === e.value.toString()}
                    value={e.value}
                    className="radio"
                  />
                  <label
                    htmlFor={`${name}_${e.value}`}
                    className="label cursor-pointer">
                    {e.label}
                  </label>
                </>
              )}
            </Field>
          </div>
        ))}
      </div>
      <FormikErrorMessage name={name} />
    </React.Fragment>
  );
};
export default FormikRadioInputView;
