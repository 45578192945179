// utils.js

import { $site } from 'stores/site';

// Function to extract subdomain from URL using regex
export const extractTenantFromUrl = (): string | null => {
  const currUrl = window.location.href;
  const url = currUrl.replace(/^https?:\/\/(?:www\.)?/, '');

  const parts = url.split('.');
  // if (url.includes('localhost')) {
  //   $site.actions.setTenant('valari');
  //   return 'valari';
  // }

  if (url.includes('localhost')) {
    $site.actions.setTenant('triple20');
    return 'triple20';
  }

  if (parts && parts[1]) {
    $site.actions.setTenant(parts[1]);
    return parts[1];
  } else {
    return null;
  }
};
