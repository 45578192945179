import React from 'react';

import { QueryClient } from '@tanstack/react-query';
import Toast from 'helperClasses/toastHelper';

const renderErrorItems = (items: string[]) => {
  return (
    <ul style={{ marginTop: '10px' }}>
      {items?.map((i) => <li key={i}>{i}</li>)}
    </ul>
  );
};

//above is the error object create one neaw function to show toast

export const showErrorToast = (err?: any) => {
  //check if network error (AxiosError)

  if (err?.code == 'ERR_NETWORK') {
    Toast.error('Network error, please try again.');
    return;
  }

  const data = err?.response?.data;

  if (data?.error?.message) {
    console.log(data?.error?.message);

    Toast.error(data?.error?.message);
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error: any) => {
        showErrorToast(error);
      },
    },
    // queries: {
    //   retry: false,
    //   refetchOnWindowFocus: false,
    //   staleTime: 60 * 1000 * 5,
    // },
  },
});
