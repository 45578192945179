import React from 'react';
import { IconProps } from './iconProps';

const SuccessCheckMark = ({ fill = 'none', w = '86', h = '86' }: IconProps) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 86 86"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_831_119582"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="86"
        height="86">
        <rect width="86" height="86" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_831_119582)">
        <path
          d="M37.9837 59.4832L63.2462 34.2207L58.2295 29.204L37.9837 49.4498L27.7712 39.2373L22.7545 44.254L37.9837 59.4832ZM43.0003 78.8332C38.0434 78.8332 33.385 77.8925 29.0253 76.0113C24.6656 74.1301 20.8732 71.5769 17.6482 68.3519C14.4232 65.1269 11.8701 61.3346 9.98887 56.9748C8.10762 52.6151 7.16699 47.9568 7.16699 42.9998C7.16699 38.0429 8.10762 33.3846 9.98887 29.0248C11.8701 24.6651 14.4232 20.8728 17.6482 17.6478C20.8732 14.4228 24.6656 11.8696 29.0253 9.98838C33.385 8.10713 38.0434 7.1665 43.0003 7.1665C47.9573 7.1665 52.6156 8.10713 56.9753 9.98838C61.3351 11.8696 65.1274 14.4228 68.3524 17.6478C71.5774 20.8728 74.1305 24.6651 76.0118 29.0248C77.893 33.3846 78.8337 38.0429 78.8337 42.9998C78.8337 47.9568 77.893 52.6151 76.0118 56.9748C74.1305 61.3346 71.5774 65.1269 68.3524 68.3519C65.1274 71.5769 61.3351 74.1301 56.9753 76.0113C52.6156 77.8925 47.9573 78.8332 43.0003 78.8332Z"
          fill="#5BC1A6"
        />
      </g>
    </svg>
  );
};

export default SuccessCheckMark;
