import { format, parseISO } from 'date-fns';

export function toApiPayloadDateFormat(
  date: string | Date | null,
  dateFormat: string = 'yyyy-MM-dd',
) {
  if (!date) {
    return '';
  }

  try {
    return format(new Date(date), dateFormat);
  } catch (error) {
    return '';
  }
}
