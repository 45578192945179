import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { Tokens } from 'types/auth';

type State = {
  id: string | null;
  isAuth: boolean;
  userType: 'owner' | 'customer' | null;
  tokens: null | Tokens;
  email: string | null;
};

const STORAGE_KEY = 'XHIBITHUB-SESSION';

const useSessionStore = create(
  persist(
    immer<State>(() => ({
      id: null,
      isAuth: false,
      tokens: null,
      email: null,
      userType: null,
    })),
    {
      name: STORAGE_KEY,
    },
  ),
);

const selectors = {
  useIsAuth: () => useSessionStore((state) => state.isAuth),
};

const actions = {
  set: useSessionStore.setState,
  // login: () => {
  //   // temp login. replace when API is ready

  //   actions.set((state) => {
  //     state.isAuth = true;
  //   });
  // },
  setSession: ({
    tokens,
    email,
    isAuth = true,
  }: {
    tokens: Tokens;
    email: string;
    isAuth?: boolean;
  }) => {
    const set = useSessionStore.setState;
    set((state) => {
      state.tokens = tokens;
      state.email = email;
      state.isAuth = isAuth;
    });
  },
  setAuth: () => {
    const set = useSessionStore.setState;
    set((state) => {
      state.isAuth = true;
    });
  },
  clearSession: () => {
    const set = useSessionStore.setState;
    set((state) => {
      state.isAuth = false;
      state.tokens = null;
      state.email = null;
      state.userType = null;
      state.id = null;
    });
  },
  // getters
  getTokens: () => useSessionStore.getState().tokens,
  getToken: () => useSessionStore.getState().tokens?.accessToken,
  getUsername: () => useSessionStore.getState().email,
  getUserId: () => useSessionStore.getState().id,
};

/**
 * **Session Store**
 *
 * this store is used to store session tokens
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $session = {
  actions,
  selectors,
} as const;

export default $session;
