import React, { FC } from 'react';

import AccountInfo from 'components/modules/AccountInfo';
import { GeneralInfoProps } from '../OrderForm.props';

const TabAccountInfo: FC<GeneralInfoProps> = (props) => {
  return (
    <div className="container mx-auto  py-2 px-5">
      <AccountInfo {...props} />
    </div>
  );
};

export default TabAccountInfo;
