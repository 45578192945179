import React, { FC, useEffect } from 'react';

import { SizeProps } from './Size.props';
import Text from '../Text';
import { Field, useField, useFormikContext } from 'formik';
import Input from '../Input';
import FormikSelectView from '../FormikSelect/FormikSelect.view';

const SizeView: FC<SizeProps> = ({
  width = 0,
  depth = 0,
  height = 0,
  mode,
}): JSX.Element => {
  const [field, meta] = useField('size');
  const { setFieldValue, values } = useFormikContext<any>();
  const errors = meta?.error && meta.touched ? Object.entries(meta.error) : [];
  return (
    <div className="grid w-full grid-cols-3 lg:grid-cols-4 items-center space-between gap-6">
      <div className="w-full flex items-center gap-2">
        <Input
          type="number"
          step=".01"
          name="package.size.width"
          min="0"
          hideError
          value={field.value !== '' ? field.value : width}
          inputContainer={`max-w-none w-20 p-0`}
          className={`max-w-xs gap-0`}
          style={{ outline: 'none', textAlign: 'center' }}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          placeholder="W"
          onClick={(event) => {
            const target = event.target as HTMLInputElement;
            target.focus();
            target.select();
          }}
        />
        <span className="">x</span>
      </div>
      <div className="w-full flex items-center gap-2">
        <Input
          type="number"
          step=".01"
          name="package.size.height"
          min="0"
          hideError
          value={field.value !== '' ? field.value : height}
          inputContainer={`max-w-none w-20 p-0`}
          className={`max-w-xs gap-0`}
          style={{ outline: 'none', textAlign: 'center' }}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          placeholder="H"
          onClick={(event) => {
            const target = event.target as HTMLInputElement;
            target.focus();
            target.select();
          }}
        />
        <span className="">x</span>
      </div>
      <div className="w-full">
        <Input
          type="number"
          step=".01"
          name="package.size.depth"
          min="0"
          hideError
          value={field.value !== '' ? field.value : depth}
          inputContainer={`max-w-none w-20 p-0`}
          className={`max-w-xs  gap-0`}
          style={{ outline: 'none', textAlign: 'center' }}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          placeholder="D"
          onClick={(event) => {
            const target = event.target as HTMLInputElement;
            target.focus();
            target.select();
          }}
        />
      </div>
      <div className="w-full">
        <FormikSelectView
          label=""
          id="unit"
          hideError
          name="package.size.unit"
          className={` w-full gap-0`}
          fieldClass="max-w-xs  bg-none pr-0 "
          value={values.package?.size?.unit}>
          <option value="">Unit</option>
          <option value="inches">Inches</option>
          <option value="feet">Feet</option>
        </FormikSelectView>
      </div>
    </div>
  );
};

export default SizeView;
