import React, { FC } from 'react';

import { MarkOrderShippedViewProps } from './MarkOrderShipped.props';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-daisyui';
import SuccessCheckMark from 'svg/successCheckMark';
import Button from 'components/primitives/Button';
import OrderForm from 'components/modules/OrderForm';

const MarkOrderShippedView: FC<MarkOrderShippedViewProps> = ({
  submitForm,
  successModalRef,
  id,
  initialValues,
  isPending,
}) => {
  const navigate = useNavigate();

  const successModal = () => (
    <div className="font-sans">
      <Modal ref={successModalRef} className="flex flex-col  items-center ">
        <Modal.Header className=" flex font-bold justify-center">
          <SuccessCheckMark />
        </Modal.Header>
        <Modal.Body>
          <div>Order Is Shipped</div>
        </Modal.Body>
        <Modal.Actions className="">
          <form method="dialog">
            <Button
              variant="outline"
              onClick={() => navigate('/ship-orders')}
              className="mx-2 rounded-btn border-blue-200 font-jakarta  text-md normal-case">
              Go To Orders List
            </Button>
            <Button
              variant="solid"
              className="mx-2 rounded-btn border-blue-200 font-jakarta  text-md normal-case"
              onClick={() => navigate('/ship-orders/summary/' + id)}>
              Go To Summary
            </Button>
          </form>
        </Modal.Actions>
      </Modal>
    </div>
  );

  return (
    <>
      {id && initialValues && (
        <OrderForm
          title="Mark Order Shipped"
          initialValues={initialValues}
          isEditable={false}
          onSubmit={submitForm}
          mode="ship"
          loading={isPending}
        />
      )}
      {successModal()}
    </>
  );
};

export default MarkOrderShippedView;
