import React, { FC } from 'react';

import { AccountInfoViewProps } from './AccountInfo.props';
import Input from 'components/primitives/Input';
import FormikDatepicker from 'components/primitives/FormikDatepicker';

const AccountInfoView: FC<AccountInfoViewProps> = ({ mode = 'create' }) => {
  return (
    <div className="grid grid-cols-2 ">
      <div className="grid col-span-1 ">
        <h5 className="col-span-1 font-jakarta">Billable</h5>

        <Input
          disabled={mode === 'ship'}
          id="handlingLoading"
          name="accountInfo.loading"
          label="Handling/Loading"
          type="number"
          step="0.1"
          labelContainer="col-span-1"
          inputContainer="col-span-2 pl-2 pr-0"
          placeholder="Your text here"
          className="flex-row my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
        />
        <Input
          disabled={mode === 'ship'}
          label="SAC"
          id="sac"
          name="accountInfo.sac"
          type="number"
          step="0.1"
          labelContainer="col-span-1"
          inputContainer="col-span-2 pl-2 pr-0"
          placeholder="Your text here"
          className="flex-row  my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
        />
        <Input
          disabled={mode === 'ship'}
          label="Receive/Check-in"
          id="receive"
          step="0.1"
          min="1"
          name="accountInfo.checkIn"
          type="number"
          labelContainer="col-span-1"
          inputContainer="col-span-2 pl-2 pr-0"
          placeholder="Your text here"
          className="flex-row  my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
        />
        <Input
          disabled={mode === 'ship'}
          label="Shipping"
          id="shipping"
          step="0.1"
          name="accountInfo.shipping"
          type="number"
          labelContainer="col-span-1"
          inputContainer="col-span-2 pl-2 pr-0"
          placeholder="Your text here"
          className="flex-row  my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
        />
        <Input
          disabled={mode === 'ship'}
          label="Repairs And Misc"
          id="repairs"
          step="0.1"
          name="accountInfo.misc"
          type="number"
          labelContainer="col-span-1"
          inputContainer="col-span-2 pl-2 pr-0"
          placeholder="Your text here"
          className="flex-row  my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
        />
        <Input
          disabled={mode === 'ship'}
          label="Total"
          step="0.1"
          id="total"
          name="accountInfo.total"
          type="number"
          labelContainer="col-span-1"
          inputContainer="col-span-2 pl-2 pr-0"
          placeholder="Your text here"
          className="flex-row  my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
        />
      </div>
      <div className="grid col-span-1 ">
        <h5 className="col-span-1 font-jakarta ">Invoicing Info</h5>

        <Input
          disabled={mode === 'ship'}
          id="invoice"
          name="accountInfo.invoice"
          label="Invoice"
          type="text"
          labelContainer="col-span-1"
          inputContainer="col-span-2"
          placeholder="Your text here"
          className="flex-row my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
        />
        <FormikDatepicker
          disabled={mode === 'ship'}
          label="Date"
          id="date"
          name="accountInfo.deliveryAt"
          labelProps="col-span-1"
          containerProps="flex-row  my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
          wrapperClass={'col-span-2'}
          inputContainer="col-span-2"
        />

        <Input
          disabled={mode === 'ship'}
          label="Total Billed"
          id="totalBilled"
          step="0.1"
          name="accountInfo.totalBilled"
          type="number"
          labelContainer="col-span-1 "
          inputContainer="col-span-2 pl-2 pr-0"
          placeholder="Your text here"
          className="flex-row  my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
        />
        <div className="my-14 col-span-1 h-12"></div>
      </div>
    </div>
  );
};

export default AccountInfoView;
