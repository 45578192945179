import React, { FC, useRef } from 'react';

import { useField } from 'formik';

import DatePicker from 'react-datepicker';
import { cn } from 'utils';

import 'react-datepicker/dist/react-datepicker.css';

import { FormikDatepickerProps } from './FormikDatepicker.props';
import { format } from 'date-fns';
import './FormikDatepicker.style.css';
import Text from '../Text';

const FormikDatepickerView: FC<
  FormikDatepickerProps & { onDateChange?: (date: String | null) => void }
> = ({
  name,
  label,
  error,
  labelProps,
  helperText,
  isEditable,
  isRequired,
  containerProps,
  wrapperClass,
  onDateChange,
  inputContainer,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ value }, meta, helpers] = useField(name);

  const selected = !!value ? new Date(value) : null;
  const displayValue = !!value ? format(new Date(value), 'MM/dd/yyyy') : '';

  const handleOnChange = (date: any) => {
    helpers.setValue(date);

    if (onDateChange) {
      let dateResponse = date ? new Date(date) : null;
      let functionDateData = dateResponse?.toISOString().split('T')[0] || null;
      onDateChange(functionDateData);
    }
    if (!isEditable) {
      if (inputRef.current && inputRef.current?.value && !!date) {
        inputRef.current.value = format(new Date(date), 'MM/dd/yyyy');
      }
    }
  };

  const moreProps = isEditable
    ? {
        onSelect: (date: Date | null) => {
          if (inputRef.current && inputRef.current?.value && !!date) {
            inputRef.current.value = format(new Date(date), 'MM/dd/yyyy');
          }
        },
        onChangeRaw: (e: React.FocusEvent<HTMLInputElement, Element>) => {
          const newRaw = new Date(e.target.value);

          if (newRaw instanceof Date && !isNaN(newRaw as any)) {
            helpers.setValue(newRaw);
            if (inputRef.current) {
              inputRef.current.value = format(new Date(newRaw), 'MM/dd/yyyy');
            }
          }
        },
      }
    : {};
  return (
    <div className={cn('mt-3', containerProps)}>
      {label && (
        <label htmlFor={name} className={cn('mt-3', labelProps)}>
          {label}
        </label>
      )}
      <div className={cn('flex flex-col', wrapperClass)}>
        <DatePicker
          wrapperClassName="formik-datepicker"
          toggleCalendarOnIconClick
          disabledKeyboardNavigation
          onChangeRaw={(e) => e.preventDefault()}
          disabled={props.disabled || false}
          selected={selected}
          onChange={handleOnChange}
          customInput={
            <input
              ref={inputRef}
              id="date-picker-input"
              defaultValue={displayValue}
              readOnly={isEditable}
              className={cn(
                `mt-2 text-jakarta input input-bordered w-full max-w-xs ${inputContainer}`,
              )}
            />
          }
          {...props}
        />
        {(error || meta.error) && (
          <Text className="text-red-500">
            {(meta.touched && meta.error) || error}
          </Text>
        )}
      </div>
    </div>
  );
};

export default FormikDatepickerView;
