import { FC } from 'react';
import loginImg from 'assets/login-img.png';
import logoImg from 'assets/triple20logo.svg';
import Button from 'components/primitives/Button';
import PageLayout from 'components/layouts/PageLayout';
import Input from 'components/primitives/Input';
import PasswordInput from 'components/primitives/Input/PasswordInput';
import GoogleButton from 'components/primitives/GoogleButton';
import poweredBy from 'assets/poweredby-xh.svg';
import MicrosoftButton from 'components/primitives/MicrosoftButton';
import { useIsMobile } from 'hooks';
import { LoginViewProps } from './Login.props';
import { Form, Formik } from 'formik';
import { initialValues, validationSchema } from 'utils/login';
import { LoginPayload } from 'types/auth';
import Text from 'components/primitives/Text';

const LoginView: FC<LoginViewProps> = ({ onSubmit, isLoading }) => {
  const isMobile = useIsMobile();

  const handleSubmit = (payload: LoginPayload) => {
    onSubmit(payload);
  };

  return (
    <PageLayout className=" ">
      <div className="flex h-screen">
        {!isMobile && (
          <div className="flex w-full  md:w-1/2">
            <img src={loginImg} alt="login" className="w-full h-auto" />
          </div>
        )}
        <div className="flex flex-col justify-center items-center w-full md:w-1/2">
          <img src={logoImg} alt="logo" className="object-cover w-56" />
          <div className="divider divider-secondary-pink-200 w-80 font-sans self-center" />
          <div className="divider divider-horizontal w-36" />
          <Text className="font-jakarta text-3xl font-semibold mt-10">
            Login
          </Text>
          <>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={validationSchema}>
              <Form className="flex flex-col items-center gap-4 w-full mt-5">
                <Input
                  label={'Username'}
                  name="email"
                  placeholder={'Enter your email'}
                  type="text"
                  className={'w-1/2 items-center '}
                  labelContainer={'self-baseline '}
                  inputContainer={'max-w-none w-full'}
                />
                <PasswordInput
                  name="password"
                  label="Password"
                  type="password"
                  placeholder={'Enter your password'}
                  className="w-1/2"
                  labelContainer="self-baseline"
                  inputContainer="max-w-none w-full"
                />
                <Button
                  variant="solid"
                  className="w-1/2 md:w-1/2"
                  type="submit">
                  Login
                </Button>
              </Form>
            </Formik>
          </>

          <img src={poweredBy} alt="logo" className="object-cover mt-5" />
        </div>
      </div>
    </PageLayout>
  );
};

export default LoginView;
