import { Client } from 'types/client';
import { ApiResponseList } from 'types/common';
import { SortProps } from 'types/tables';
import { api } from './instance';

export const getClients = async (params?: {
  page?: number;
  limit?: number;
  sort?: SortProps<Client>;
  status?: number;
}) => {
  try {
    const { data } = await api.get<ApiResponseList<Client>>('/client/list', {
      params: {
        page: params?.page || 1,
        sort: params?.sort,
        status: params?.status,
        limit: 100,
      },
    });
    return data.data;
  } catch (error) {
    console.log(error);
  }
};
