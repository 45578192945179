import React, { FC, useEffect, useState } from 'react';
import { SearchInputProps } from './SearchInput.props';
import SearchInputView from './SearchInput.view';
import { useQuery } from '@tanstack/react-query';
import useDebounce from 'hooks/useDebounce';

const SearchInputContainer: FC<SearchInputProps> = ({
  handleSearchChange,
  searchFn,
  onSelect,
  debounceTime = 1000,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearch = useDebounce(searchValue, debounceTime);

  const { data, isLoading } = useQuery({
    queryKey: ['SEARCH_ITEMS', debouncedSearch],
    queryFn: () => searchFn(searchValue),
    staleTime: 0, // This is set to 0 to ensure the API triggers when the client ID changes in Ship Order, so the add items search bar reflects the correct client
    enabled: !!debouncedSearch, // should not be triggered on mount
  });

  const handleSearchInputChange = (searchStr: string) => {
    setSearchValue(searchStr);
    handleSearchChange && handleSearchChange(searchStr);
  };
  return (
    <SearchInputView
      handleSearchChange={handleSearchInputChange}
      searchResults={data?.items}
      isLoading={isLoading}
      value={searchValue}
      onSelect={(val) => {
        setSearchValue('');
        onSelect && onSelect(val);
      }}
    />
  );
};

export default SearchInputContainer;
