import React, { FC, useRef, useState } from 'react';

import { SearchInputViewProps } from './SearchInput.props';
import SearchBar from 'components/primitives/SearchBar';

const SearchInputView: FC<SearchInputViewProps> = ({
  handleSearchChange,
  searchResults,
  isLoading,
  onSelect,
  value,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const [open, setOpen] = useState(false);
  const handleOnClick = (res: any) => {
    if (onSelect) {
      onSelect(res);
    }
    setOpen(false);
  };
  return (
    <div
      className={`dropdown z-10 w-full ${open ? 'dropdown-open' : 'dropdown-close'}`}
      ref={ref}>
      <SearchBar
        inputRef={inputRef}
        tabIndex={0}
        value={value}
        onChange={(value) => {
          handleSearchChange && handleSearchChange(value);
          setOpen(true);
        }}
      />
      <div
        className={`${!open ? 'dropdown-content' : ''} bg-base-200 top-14 max-h-96 overflow-auto flex-col rounded-md`}>
        {isLoading && (
          <div className="absolute inset-0 flex justify-center items-center">
            <span className="loading loading-spinner loading-sm"></span>
          </div>
        )}
        <ul
          className="menu menu-compact p-0"
          style={{ width: ref.current?.clientWidth }}>
          {searchResults &&
            open &&
            searchResults.length > 0 &&
            searchResults.map((res: any, index: number) => {
              return (
                <li
                  key={index}
                  tabIndex={index + 1}
                  onClick={() => {
                    handleOnClick(res);
                    clearInput();
                  }}
                  className="border-b border-b-base-content/10 w-full">
                  <button type="button">
                    {res.name} - {res.code}
                  </button>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default SearchInputView;
