import React, { FC, useEffect } from 'react';

import Input from 'components/primitives/Input';
import FormikSelectView from 'components/primitives/FormikSelect/FormikSelect.view';
import FormikRadioButton from 'components/primitives/FormikRadioButton';
import { useFormikContext } from 'formik';
import FormikDateRangepicker from 'components/primitives/FormikDateRangepicker';
import FormikDatepicker from 'components/primitives/FormikDatepicker';
import { GeneralInfoViewProps } from '../OrderForm.props';
import { useMutation } from '@tanstack/react-query';
import { STATES } from 'stores/states/state';
import FormikRadioInput from 'components/primitives/FormikRadioInput';

const GeneralInfo: FC<GeneralInfoViewProps> = ({
  clients,
  loading,
  users,
  mode = 'create',
}) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const className = 'flex-row  my-1 col-span-1 grid grid-cols-1 md:grid-cols-3';
  useEffect(() => {
    if (values.client && clients.length > 0) {
      onChangeClient(values.client);
    }
  }, [values.client, clients]);

  useEffect(() => {
    setFieldValue('contact.contactName', values.contact.contactName || '');
    setFieldValue('contact.phoneNumber', values.contact.phoneNumber || '');
  }, [values.isClientBillToAccount]);

  const onChangeClient = (clientId: string) => {
    if (clients.length > 0) {
      const clientObj = clients.find((val) => val._id === clientId);
      const primaryContact = clientObj?.contact?.find(
        (val) => val.primaryContact,
      );
      setFieldValue('clientInfo.name', clientObj?.name || '');
      setFieldValue('clientInfo.location', clientObj?.location || null);
      setFieldValue(
        'clientInfo.billingAddress',
        clientObj?.billingAddress || '',
      );
      setFieldValue(
        'clientInfo.physicalAddress',
        clientObj?.physicalAddress || null,
      );
      setFieldValue('address.state', values.address.state || null);
      setFieldValue('clientInfo.contact', primaryContact?.contactName || '');
      setFieldValue('clientInfo.phone', primaryContact?.phone || null);
      setFieldValue('clientInfo.email', primaryContact?.email || '');
    }
  };

  const clearItems = () => {
    setFieldValue('orderDetail', []);
    localStorage.removeItem('orderItems');
  };

  return (
    <>
      <h5 className="font-jakarta">General Info</h5>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="grid gap-4">
          <FormikSelectView
            disabled={mode !== 'create'}
            label="Client"
            name="client"
            className={className}
            labelContainer="col-span-1 required"
            fieldContainer="col-span-2 max-w-xs"
            value={values.client}
            onChange={(_id) => {
              onChangeClient(_id);
              clearItems();
            }}
            id="client">
            <option value="">Your Text Here</option>
            {clients.map((client) => (
              <option value={client._id}>{client.name}</option>
            ))}
          </FormikSelectView>

          <div className="flex items-center font-light">
            <label className="w-1/3 required">Bill To Account</label>
            <div className="w-2/3">
              <FormikRadioInput
                disabled={mode === 'ship'}
                onClick={(value) =>
                  setFieldValue('isClientBillToAccount', value)
                }
                items={[
                  {
                    label: 'Tenant',
                    value: true,
                  },
                  {
                    label: 'Client',
                    value: false,
                  },
                ]}
                name={'isClientBillToAccount'}
              />
            </div>
          </div>

          <Input
            disabled={mode === 'ship'}
            label="Event"
            id="event"
            name="event"
            type="text"
            placeholder="Your text here"
            className={className}
            labelContainer="col-span-1 required"
            inputContainer="col-span-2"
          />
          <FormikDatepicker
            disabled={mode === 'ship'}
            isRequired
            label="Ship Date"
            name="shipDate"
            id="shipDate"
            labelProps={`col-span-1 ${mode !== 'create' ? 'required' : ''}`}
            containerProps={className}
            inputContainer="col-span-2"
            wrapperClass={'col-span-2'}
          />
          {/* <Input
            disabled={mode === 'ship'}
            label="Booth#"
            id="booth"
            name="booth"
            type="text"
            placeholder="Your text here"
            className={className}
            labelContainer="col-span-1"
            inputContainer="col-span-2"
          /> */}

          <FormikSelectView
            disabled={mode === 'ship'}
            label="Shipping Preference"
            id="shippingPreference"
            name="shippingPreference"
            className={className}
            value={values.shippingPreference}
            labelContainer="col-span-1 required"
            fieldContainer="col-span-2 max-w-xs">
            <option key="empty" value="">
              Your Text Here
            </option>
            <option key={'fedex'} value="Fedex">
              Fedex
            </option>
            <option key={'ups'} value="UPS">
              UPS
            </option>
            <option key={'usps'} value="USPS">
              USPS
            </option>
            <option key={'courier'} value={'Courier'}>
              Courier
            </option>
            <option key={'frieght'} value={'Freight'}>
              Freight
            </option>
          </FormikSelectView>
          <FormikDatepicker
            disabled={mode === 'ship'}
            isRequired
            label="Return Date"
            name="returnDate"
            id="returnDate"
            labelProps="col-span-1 "
            containerProps={className}
            inputContainer="col-span-2"
            wrapperClass={'col-span-2'}
          />

          <FormikDatepicker
            disabled={mode === 'ship'}
            label="Prep By Date"
            name="prepByDate"
            id="prepByDate"
            labelProps="col-span-1"
            containerProps={className}
            wrapperClass={'col-span-2'}
            inputContainer="col-span-2"
          />
          <FormikDateRangepicker
            disabled={mode === 'ship'}
            containerClass={className}
            name="advanceWarehouseDate"
            label="Adv. Wrhse Dates"
            labelClass="col-span-1"
            fieldContainer="col-span-2 max-w-xs"
            fieldClass="max-w-full"
          />

          {mode === 'process' && (
            <Input
              label="Tracking Number"
              id="transactionNumber"
              name="transactionNumber"
              type="text"
              placeholder="Your text here"
              className={className}
              labelContainer="col-span-1 required"
              inputContainer="col-span-2 "
            />
          )}

          <FormikSelectView
            disabled={mode === 'ship'}
            label="Project Manager"
            id="projectManager"
            name="projectManager"
            className={className}
            value={values.projectManager}
            labelContainer="col-span-1 required"
            fieldContainer="col-span-2 max-w-xs">
            <option value="">Your Text Here</option>
            {users.map((user) => (
              <option value={user._id}>
                {user.firstName} {user.lastName}
              </option>
            ))}
          </FormikSelectView>
        </div>

        <div className="grid gap-4">
          <Input
            disabled={mode === 'ship'}
            label="Account Number"
            id="accountNumber"
            name="accountNumber"
            type="text"
            placeholder="Your text here"
            className={className}
            labelContainer="col-span-1"
            inputContainer="col-span-2 "
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
          />
          <div className="flex items-center font-light">
            <label className="w-1/3 required">Returnable</label>
            <div className="w-2/3">
              <FormikRadioInput
                onClick={(value) => setFieldValue('isReturnable', value)}
                items={[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ]}
                name={'isReturnable'}
              />
            </div>
          </div>

          <Input
            label="Recipient Name"
            id="recipient.name"
            name="recipient.name"
            type="text"
            placeholder="Your text here"
            className={className}
            labelContainer="col-span-1 "
            inputContainer="col-span-2 "
          />

          <Input
            label="Recipient Email"
            id="recipient.email"
            name="recipient.email"
            type="text"
            placeholder="Your text here"
            className={className}
            labelContainer="col-span-1 "
            inputContainer="col-span-2 "
          />
          <Input
            label="Recipient Phone"
            required
            id="recipient.phone"
            name="recipient.phone"
            type="text"
            placeholder="Your text here"
            className={className}
            labelContainer="col-span-1 "
            inputContainer="col-span-2 "
          />
          {/* 
          {mode && mode !== 'create' && (
            <Input
              label="Recipient Name"
              id="contactName"
              name="contact.contactName"
              type="text"
              labelContainer="col-span-1 required"
              inputContainer="col-span-2"
              placeholder="e.g., Jane Smith"
              className="flex-row my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
            />
          )} */}

          <FormikDatepicker
            disabled={mode === 'ship'}
            isRequired
            label="Arrival Date"
            name="arrivalDate"
            id="arrivalDate"
            labelProps="col-span-1 required"
            containerProps={className}
            inputContainer="col-span-2"
            wrapperClass={'col-span-2'}
          />

          {/* {mode && mode !== 'create' && (
            <Input
              label="Recipient Phone"
              id="phoneNumber"
              name="contact.phoneNumber"
              type="tel"
              labelContainer="col-span-1 required"
              inputContainer="col-span-2"
              placeholder="e.g., (987) 654-3210"
              className="flex-row my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
            />
          )} */}

          <FormikDatepicker
            disabled={mode === 'ship'}
            isRequired
            label="Pickup Date"
            name="pickupDate"
            id="pickupDate"
            labelProps="col-span-1 required"
            containerProps={className}
            inputContainer="col-span-2"
            wrapperClass={'col-span-2'}
          />

          <Input
            disabled={mode === 'ship'}
            label="Delivery Address"
            id="deliveryAddress"
            name="address.addressLine1"
            type="text"
            placeholder="Your text here"
            className={className}
            labelContainer="col-span-1 required"
            inputContainer="col-span-2"
          />

          <Input
            disabled={mode === 'ship'}
            label="Apartment, Suite etc."
            id="apartment"
            name="address.addressLine2"
            type="text"
            placeholder="Your text here"
            className={className}
            labelContainer="col-span-1"
            inputContainer="col-span-2"
          />

          <Input
            disabled={mode === 'ship'}
            label="City"
            id="city"
            name="address.city"
            type="text"
            placeholder="Your text here"
            className={className}
            labelContainer="col-span-1 required"
            inputContainer="col-span-2"
          />

          <FormikSelectView
            label="State"
            id="state"
            disabled={mode === 'ship'}
            name="address.state"
            className={className}
            labelContainer="col-span-1 required"
            fieldContainer="col-span-2 max-w-xs h-20 max-h-20"
            fieldClass="h-10"
            value={values.address.state}>
            {STATES.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </FormikSelectView>

          <Input
            disabled={mode === 'ship'}
            label="Zip Code"
            id="zipcode"
            name="address.zip"
            type="text"
            placeholder="Your text here"
            className={className}
            labelContainer="col-span-1 required"
            inputContainer="col-span-2"
          />
        </div>
      </div>
    </>
  );
};

export default GeneralInfo;
