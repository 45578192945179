import React from 'react';

import { cn } from 'utils';

import { ButtonProps } from './Button.props';

import './Button.css';

const ButtonView = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      variant,
      children,
      className,
      isLoading,
      isDisabled,
      plusIcon,
      ...defaultProps
    } = props;

    const btnDisabled = isDisabled ? 'btn-disabled' : '';
    const btnColoscheme = 'bg-blue-200 hover:bg-blue-250 hover:border-blue-250';
    const btnVariant =
      variant === 'solid'
        ? 'btn text-white'
        : 'btn btn-outline border-2 border-blue-250 text-blue-250';
    const btnBg = variant === 'outline' ? 'bg-white' : '';

    return (
      <button
        ref={ref}
        className={cn(
          'uppercase',
          'font-sans',
          isLoading ? 'pointer-events-none' : '',
          btnVariant,
          btnColoscheme,
          btnDisabled,
          className,
          btnBg,
        )}
        {...defaultProps}>
        {plusIcon && <span className="material-icons">add</span>}
        {children}
        {isLoading && <span className="loading loading-spinner"></span>}
      </button>
    );
  },
);

export default ButtonView;
