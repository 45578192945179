import React, { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';
import Input from 'components/primitives/Input';

interface ClientInfoProps {
  mode?: string;
}

const ClientInfo: FC<ClientInfoProps> = ({ mode }) => {
  const { setFieldValue, values } = useFormikContext<any>();
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      {/* Client Info Section */}
      <div className="grid col-span-1 gap-4 p-5">
        <h5 className="col-span-1 font-jakarta">Client Info</h5>
        <Input
          disabled={true}
          id="clientName"
          name="clientInfo.name"
          label="Client Name"
          type="text"
          labelContainer="col-span-1 required"
          inputContainer="col-span-2"
          placeholder="e.g., Acme Corp."
          className="flex-row my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
          value={values.name}
        />
        <Input
          disabled={true}
          id="location"
          name="clientInfo.location"
          label="Location"
          type="text"
          labelContainer="col-span-1 required"
          inputContainer="col-span-2"
          placeholder="e.g., New York, NY"
          className="flex-row my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
          value={values.location}
        />
        <Input
          disabled={true}
          label="Billing Address"
          id="billingAddress"
          name="clientInfo.billingAddress"
          type="text"
          labelContainer="col-span-1 required"
          inputContainer="col-span-2"
          placeholder="e.g., 123 Main St, Suite 500"
          className="flex-row my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
          value={values.billingAddress}
        />
        <Input
          disabled={true}
          label="Physical Address"
          id="physicalAddress"
          name="clientInfo.physicalAddress"
          type="text"
          labelContainer="col-span-1 required"
          inputContainer="col-span-2"
          placeholder="e.g., 456 Elm St"
          className="flex-row my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
          value={values.physicalAddress}
        />
      </div>
      {/* Primary Contact Section */}
      <div className="grid col-span-1 gap-4 p-5">
        <h5 className="col-span-1 font-jakarta">Primary Contact</h5>
        <Input
          disabled={true}
          label="Contact Name"
          id="contact"
          name="clientInfo.contact"
          type="text"
          labelContainer="col-span-1 required"
          inputContainer="col-span-2"
          placeholder="e.g., John Doe"
          className="flex-row my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
          value={values.contact}
        />
        <Input
          disabled={true}
          label="Phone Number"
          id="phone"
          name="clientInfo.phone"
          type="tel"
          labelContainer="col-span-1 required"
          inputContainer="col-span-2"
          placeholder="e.g., (123) 456-7890"
          className="flex-row my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
          value={values.phone}
        />
        <Input
          disabled={true}
          label="Email Address"
          id="email"
          name="clientInfo.email"
          type="text"
          labelContainer="col-span-1 required"
          inputContainer="col-span-2"
          placeholder="e.g., john.doe@example.com"
          className="flex-row my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
          value={values.email}
        />
        <div className="my-1 col-span-1 h-12"></div>
      </div>
    </div>
  );
};

export default ClientInfo;
