import React, { FC, useEffect, useState } from 'react';

import { ItemsToShipProps, OrderItems } from './ItemsToShip.props';

import ItemsToShipView from './ItemsToShip.view';
import { Item } from 'types/item';
import { useFormikContext } from 'formik';

const ItemsToShipContainer: FC<ItemsToShipProps> = (props) => {
  const { setFieldValue, values } = useFormikContext<any>();

  const savedOrderItems = localStorage.getItem('orderItems') || '';
  const initialOrderItems =
    savedOrderItems && JSON.parse(savedOrderItems).length > 0
      ? JSON.parse(savedOrderItems)
      : values.orderDetail
        ? values.orderDetail
        : [];

  const [orderItems, setOrderItems] =
    useState<Record<any, any>[]>(initialOrderItems);

  useEffect(() => {
    setFieldValue('orderDetail', orderItems);
    localStorage.setItem('orderItems', JSON.stringify(orderItems));
  }, [orderItems]);

  const onSearchResultSelect = (search: Item) => {
    const { _id, name, category, variants, images } = search;
    const existingItemIndex = orderItems.findIndex((i) => i.item._id === _id);
    if (existingItemIndex !== -1) {
      const _orderItems = [...orderItems];
      if (_orderItems[existingItemIndex]?.quantity) {
        _orderItems[existingItemIndex].quantity! += 1;
        setOrderItems(_orderItems);
      }
    } else {
      const variant = (variants.length > 0 && variants[0]._id) || '';
      const price = (variants.length > 0 && variants[0].price) || 0;
      const newItem = {
        item: search,
        variant,
        quantity: 1,
        price,
      };

      const items = [...orderItems, newItem];
      setOrderItems(items);
    }
  };

  const handleQuantityChange = (itemId: string, quantity: number) => {
    if (quantity === 0) {
      setOrderItems(orderItems.filter((item) => item._id !== itemId));
    } else {
      setOrderItems(
        orderItems.map((i) => {
          if (i.item._id === itemId) {
            return { ...i, quantity };
          }
          return i;
        }),
      );
    }
  };
  const handleDelete = (itemId: string) => {
    setOrderItems(orderItems.filter((i) => i.item._id !== itemId));
  };
  return (
    <ItemsToShipView
      project="ProjectName"
      orderItems={orderItems}
      onSearchResultSelect={onSearchResultSelect}
      handleQuantityChange={handleQuantityChange}
      handleDelete={handleDelete}
      {...props}
    />
  );
};

export default ItemsToShipContainer;
