import React from 'react';
import { IconProps } from './iconProps';

const ProjectsIcon = ({ w = '14', h = '13', className = '' }: IconProps) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 14 13"
      fill="none"
      className={className}>
      <path
        d="M1.66732 13C1.30065 13 0.986762 12.8694 0.725651 12.6083C0.46454 12.3472 0.333984 12.0333 0.333984 11.6666V4.33331C0.333984 3.96665 0.46454 3.65276 0.725651 3.39165C0.986762 3.13054 1.30065 2.99998 1.66732 2.99998H4.33398V1.66665C4.33398 1.29998 4.46454 0.986091 4.72565 0.72498C4.98676 0.463869 5.30065 0.333313 5.66732 0.333313H8.33398C8.70065 0.333313 9.01454 0.463869 9.27565 0.72498C9.53676 0.986091 9.66732 1.29998 9.66732 1.66665V2.99998H12.334C12.7007 2.99998 13.0145 3.13054 13.2757 3.39165C13.5368 3.65276 13.6673 3.96665 13.6673 4.33331V11.6666C13.6673 12.0333 13.5368 12.3472 13.2757 12.6083C13.0145 12.8694 12.7007 13 12.334 13H1.66732ZM5.66732 2.99998H8.33398V1.66665H5.66732V2.99998Z"
        stroke="none"
      />
    </svg>
  );
};

export default ProjectsIcon;
