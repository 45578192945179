import React, { FC, useEffect } from 'react';
import { format } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { cn } from 'utils';
import { FormikDatepickerProps } from './FormikDateRangepicker.props';
import Text from '../Text';

const FormikDateRangepickerView: FC<FormikDatepickerProps> = ({
  name,
  label,
  labelClass,
  containerClass,
  fieldContainer,
  fieldClass,
  formatInUTC = false,
  readonly = false,
  disabled = false,
  ...props
}) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const [startField, startMeta, startHelpers] = useField(`${name}.startDate`);
  const [endField, endMeta, endHelpers] = useField(`${name}.endDate`);

  const onChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setFieldValue(`${name}.startDate`, start);
    setFieldValue(`${name}.endDate`, end);
  };

  useEffect(() => {
    startHelpers.setValue(startField.value);
    endHelpers.setValue(endField.value);
  }, [startField.value, endField.value]);

  const startDate = startField.value ? new Date(startField.value) : null;
  const endDate = endField.value ? new Date(endField.value) : null;
  const displayValue =
    startDate && endDate
      ? `${format(startDate, 'MM/dd/yyyy')} - ${format(endDate, 'MM/dd/yyyy')}`
      : '';

  return (
    <div className={cn(`flex gap-2 ${containerClass}`)}>
      <div className={labelClass}>
        <label htmlFor={`date-picker-range-input-${name}`}>{label}</label>
      </div>
      <div className={cn(`flex flex-col ${fieldContainer}`)}>
        <DatePicker
          disabled={disabled}
          disabledKeyboardNavigation
          selectsRange
          startDate={startDate}
          endDate={endDate}
          selected={startDate}
          onChange={onChange}
          onChangeRaw={(e) => e.preventDefault()}
          isClearable
          customInput={
            <input
              name={name}
              value={displayValue}
              readOnly={readonly}
              id={`date-picker-range-input-${name}`}
              className={cn(
                `select select-bordered w-full text-secondary max-w-xs ${fieldClass}`,
              )}
            />
          }
          {...props}
        />
        {startMeta.touched && startMeta.error && (
          <Text className="text-red-500 mt-1">{startMeta.error}</Text>
        )}
        {endMeta.touched && endMeta.error && (
          <Text className="text-red-500 mt-1">{endMeta.error}</Text>
        )}
      </div>
    </div>
  );
};

export default FormikDateRangepickerView;
