import React, { FC } from 'react';

import { SearchBarProps } from './SearchBar.props';
import SearchIcon from 'svg/searchIcon';
import { cn } from 'utils';

const SearchBarView: FC<SearchBarProps> = ({
  placeholder,
  className = '',
  onChange,
  value = '',
  inputRef,
  ...props
}) => {
  return (
    <label
      className={cn(
        `input input-sm input-bordered flex items-center gap-2 ${className}`,
      )}>
      <SearchIcon fill="#AFAFAF" />
      <input
        ref={inputRef}
        type="text"
        className="grow w-full"
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        {...props}
      />
    </label>
  );
};

export default SearchBarView;
