import React, { FC } from 'react';

import { StatusBadgeProps } from './StatusBadge.props';
import { ItemType } from 'types/item';
import { cn } from 'utils';
const StatusBadgeView: FC<StatusBadgeProps> = ({
  status,
  className,
  isLoading = false,
}) => {
  let bgColor, textColor;

  switch (status?.toLowerCase()) {
    case 'client item':
      bgColor = 'bg-pastel-orange-50';
      textColor = 'text-orange-100';
      break;
    case 'fullfillment':
      bgColor = 'bg-pastel-red-150';
      textColor = 'text-pink-250';
      break;
    case 'structural components':
      bgColor = 'bg-pastel-pink-110';
      textColor = 'text-pink-190';
      break;
    case 'rental item':
      bgColor = 'bg-blue-270';
      textColor = 'text-pastel-blue-150';
      break;
    case 'collateral':
      bgColor = 'bg-pastel-green-150';
      textColor = 'text-cream-100';
      break;
    case 'portables':
      bgColor = 'bg-blue-400';
      textColor = 'text-blue-300';
      break;
    case 'processed':
      bgColor = 'bg-blue-200';
      textColor = 'text-white';
      break;
    case 'ongoing':
      bgColor = 'bg-pastel-green-100';
      textColor = 'text-white';
      break;
    case 'shipped':
      bgColor = 'bg-pastel-red-100';
      textColor = 'text-white';
      break;
    case 'returned':
      bgColor = 'bg-orange-500';
      textColor = 'text-white';
      break;
    case 'archived':
      bgColor = 'bg-pastel-orange-100';
      textColor = 'text-white';
      break;
    case 'done':
      bgColor = 'bg-green-700';
      textColor = 'text-white';
      break;
    case 'deleted':
      bgColor = 'bg-pastel-orange-100';
      textColor = 'text-white';
      break;
    case 'created':
      bgColor = 'bg-pastel-orange-55';
      textColor = 'text-white';
      break;
    default:
      bgColor = 'bg-gray-500';
      textColor = 'text-gray-100';
  }

  return (
    <div
      className={cn(
        `badge badge-lg text-sm rounded-md ${bgColor} ${textColor} ${isLoading && 'animate-pulse'}  flex justify-center items-center`,
        className,
      )}>
      {status}
    </div>
  );
};

export default StatusBadgeView;
