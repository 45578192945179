import React, { FC } from 'react';

import { ItemsToShipViewProps } from './ItemsToShip.props';

import SearchBar from 'components/primitives/SearchBar';
import Input from 'components/primitives/Input';
import StatusBadge from 'components/primitives/StatusBadge';
import SearchInput from '../SearchInput';
import { fetchItems } from 'services/items';
import Delete from 'svg/delete';
import { useField } from 'formik';
import Text from 'components/primitives/Text';
import { OrderStatus } from 'types/shipOrders';

const ItemsToShipView: FC<ItemsToShipViewProps> = ({
  project,
  orderItems,
  onSearchResultSelect,
  handleQuantityChange,
  handleDelete,
  mode = 'create',
  status,
}) => {
  const [errorMessage, meta] = useField('orderDetail');
  const [field] = useField('client');
  const { value: clientId } = field;

  return (
    <div className="flex flex-col justify-start gap-3">
      <h3>Items To Ship For {project}</h3>
      <h5 className="text-primary-100 ">
        These are the items to ship for this project, feel free to add anything
        else that is needed
      </h5>
      <div>
        {mode != 'ship' && (
          <SearchInput
            onSelect={onSearchResultSelect}
            searchFn={(searchValue: string) =>
              fetchItems({ search: searchValue, clientId, isArchived: false })
            }
          />
        )}
      </div>
      <div className="flex flex-wrap items-center">
        <div className="mr-4 mb-4">
          <label className="block mb-1">Filter by</label>
        </div>
        <div className="flex flex-wrap ">
          <select
            name="exhibitMaterial"
            className="select select-sm  select-bordered  text-secondary  max-w-xs mb-2  mr-4">
            <option value="">Exhibit Material</option>
          </select>
          <select
            name="graphics"
            className="select select-sm  select-bordered  text-secondary  max-w-xs mb-2  mr-4">
            <option value="">Graphics</option>
          </select>
          <select
            name="giveaways"
            className="select select-sm  select-bordered  text-secondary  max-w-xs mb-2  mr-4">
            <option value="">Giveaways</option>
          </select>
          <select
            name="productDisplay"
            className="select select-sm  select-bordered  text-secondary  max-w-xs mb-2  mr-4">
            <option value="">Product Display</option>
          </select>
          <select
            name="portables"
            className="select select-sm  select-bordered  text-secondary  max-w-xs mb-2  mr-4">
            <option value="">Portables</option>
          </select>
          <select
            name="fullfillment"
            className="select select-sm  select-bordered  text-secondary  max-w-xs mb-2  mr-4">
            <option value="">Fullfillment</option>
          </select>
          <select
            name="showServices"
            className="select select-sm  select-bordered  text-secondary  max-w-xs mb-2  mr-4">
            <option value="">Show Services</option>
          </select>
        </div>
      </div>
      <div>
        <div className="flex flex-col w-full font-sans text-primary-350 ">
          {meta.error && meta.touched && (
            <Text className="text-lg font-bold">{meta.error}</Text>
          )}
          {orderItems.map((value) => {
            const imageSrc =
              value?.item?.images && value.item.images.length > 0
                ? `${process.env.REACT_APP_STORAGE_URL}/${value.item.images[0]}`
                : '';
            const variantId = value?.variant?.toString();
            const inventories =
              value.item?.inventories?.filter(
                (inv) => inv?.variant == variantId,
              ) || [];
            const availableQty =
              inventories.length > 0
                ? inventories[0].quantity
                : value.inventory?.quantity ?? 0;
            return (
              <div
                key={value?.item?._id}
                className="border-b-2 border-color-gray flex items-center">
                <div className="avatar placeholder mr-5">
                  <div className="bg-slate-300 text-neutral-content rounded w-10">
                    {imageSrc ? (
                      <img src={imageSrc} />
                    ) : (
                      <div className="w-full h-40 bg-gray-200"></div>
                    )}
                  </div>
                </div>{' '}
                <div className="w-2/5">
                  <Text className="text-lg"> {value?.item?.name}</Text>
                </div>
                <div className=" w-1/5">
                  <Text className="text-lg">
                    Remaining Quantity:{' '}
                    {availableQty - (value.quantity ? value.quantity : 0)}
                  </Text>
                </div>
                {/* TODO <div>
                <StatusBadge status={value.itemType || ''} />
              </div> */}
                <div className="w-1/6">
                  <StatusBadge status={value?.item?.code || ''} />
                </div>
                {/* TODO <div>
                <StatusBadge status={value.subcategory || ''} />
              </div> */}
                <div className="flex items-center  w-1/6">
                  <Text className="text-lg"> Quantity</Text>
                  <Input
                    disabled={mode === 'ship'}
                    label=""
                    placeholder=""
                    type="number"
                    name={`quantity-${value.item?._id}`} // Ensure unique name for each input
                    value={value.quantity}
                    inputContainer="pl-2 pr-0 "
                    onChange={(qty) => {
                      handleQuantityChange(value.item?._id!, parseInt(qty));
                    }}
                    className="w-14 mx-2 mb-1"
                  />
                  {status === OrderStatus.CREATED ||
                    (status === OrderStatus.BEING_CREATED && (
                      <div
                        className="px-2"
                        onClick={() => handleDelete(value.item?._id!)}>
                        {' '}
                        <Delete />
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ItemsToShipView;
