import { useNavigate } from 'react-router-dom';
import { $session } from 'stores';
import { queryClient } from 'utils';

export const useLogout = () => {
  const navigate = useNavigate();

  const clearSession = () => {
    $session.actions.clearSession();
    queryClient.invalidateQueries();
    navigate('/');
  };

  return clearSession;
};
