import React, { FC, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';

import Input from 'components/primitives/Input';
import FormikTextArea from 'components/primitives/FormikTextArea';
import Size from 'components/primitives/Size';
import { GeneralInfoProps } from '../OrderForm.props';
import FormikErrorMessage from 'components/core/FormikErrorMessage';

const SizeWeight: FC<GeneralInfoProps> = ({ mode }) => {
  const { setFieldValue, values } = useFormikContext<any>();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-4 bg-primary-300 p-5 mt-5">
      <div className="flex ">
        <label className={` w-1/3 ${mode === 'create' ? '' : 'required'} `}>
          Size
        </label>
        <div className="w-4/5">
          <Size mode={mode} />
          <ul className="mt-2">
            <li>
              <FormikErrorMessage name="package.size.width" />
            </li>
            <li>
              <FormikErrorMessage name="package.size.height" />
            </li>
            <li>
              <FormikErrorMessage name="package.size.depth" />
            </li>
            <li>
              <FormikErrorMessage name="package.size.unit" />
            </li>
          </ul>
        </div>
      </div>
      <div className="flex items-center">
        <Input
          required={mode != 'create'}
          label="Weight"
          id="weight"
          name="package.weight"
          type="number"
          placeholder="Your text here"
          className="flex-row my-1 col-span-1 grid grid-cols-1 md:grid-cols-3"
          labelContainer={`col-span-1 `}
          inputContainer="col-span-2"
          onClick={(event) => {
            const target = event.target as HTMLInputElement;
            target.focus();
            target.select();
          }}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
        <div className="ml-2">lb</div>
      </div>
      <FormikTextArea
        label="Notes"
        name="notes"
        id="notes"
        placeholder="Your text here"
        labelContainer="col-span-1"
        fieldContainer="md:col-span-4"
        className="flex-row  my-1 col-span-2  grid md:grid-cols-6 gap-0"
      />
    </div>
  );
};

export default SizeWeight;
