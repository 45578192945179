import React, { FC, InputHTMLAttributes } from 'react';
import { FormikTextAreaProps } from './FormikTextArea.props';
import { cn } from 'utils';
import { Textarea } from 'react-daisyui';
import { useField } from 'formik';
import Text from '../Text';

const FormikTextArea: FC<FormikTextAreaProps> = ({
  label,
  className,
  name,
  labelContainer,
  fieldContainer,
  id,
  textAreaClassName,
  ...FormikTextAreaProps
}): JSX.Element => {
  const [field, meta, helpers] = useField(name);

  return (
    <>
      <div className={cn(`flex flex-col gap-2 ${className}`)}>
        <div className={labelContainer}>
          <label htmlFor={id}>{label}</label>
        </div>
        <div className={fieldContainer}>
          <Textarea
            {...FormikTextAreaProps}
            value={field.value ?? ''}
            onChange={(e) => helpers.setValue(e.target?.value)}
            className={cn(
              `textarea textarea-bordered textarea-xs w-full md:resize-y ${textAreaClassName}`,
            )}
          />
        </div>
      </div>
      {meta.error && meta.touched && (
        <Text className="text-red-500  mt-1">{meta.error}</Text>
      )}
    </>
  );
};

export default FormikTextArea;
