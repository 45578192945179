export const inventoryFilters = ['All', 'Client', 'Category'];

export const items = [
  {
    id: '1',
    image: 'Image1.jpg',
    name: 'Product A',
    client: 'Client X',
    location: 'Location 1',
    type: 'Booth material',
    quantity: 10,
    status: 'Optimal stock',
    category: 'exhibit components',
    subcategory: 'Furniture',
  },
  {
    id: '2',
    image: 'Image2.jpg',
    name: 'Product B',
    client: 'Client Y',
    location: 'Location 2',
    type: 'Display product',
    quantity: 5,
    status: 'In use',
    category: 'Collateral',
    subcategory: 'Furniture',
  },
  {
    id: '3',
    image: 'Image3.jpg',
    name: 'Product C',
    client: 'Client Z',
    location: 'Location 3',
    type: 'Booth material',
    quantity: 15,
    status: 'Restock',
    category: 'Portables',
    subcategory: 'Graphics',
  },
  {
    id: '4',
    image: 'Image4.jpg',
    name: 'Product D',
    client: 'Client X',
    location: 'Location 1',
    type: 'Display product',
    quantity: 8,
    status: 'Depleted',
    category: 'Collateral',
    subcategory: 'Giveaways',
  },
  {
    id: '5',
    image: 'Image5.jpg',
    name: 'Product E',
    client: 'Client Y',
    location: 'Location 2',
    type: 'Booth material',
    quantity: 20,
    status: 'In use',
    category: 'Fullfillment',
    subcategory: 'Supply kits',
  },
  {
    id: '6',
    image: 'Image6.jpg',
    name: 'Product F',
    client: 'Client Z',
    location: 'Location 3',
    type: 'Display product',
    quantity: 3,
    status: 'Deactivated',
    category: 'Exhibit Components',
    subcategory: 'Crates',
  },
  {
    id: '7',
    image: 'Image7.jpg',
    name: 'Product G',
    client: 'Client X',
    location: 'Location 1',
    type: 'Booth material',
    quantity: 12,
    status: 'Optimal stock',
  },
  {
    id: '8',
    image: 'Image8.jpg',
    name: 'Product H',
    client: 'Client Y',
    location: 'Location 2',
    type: 'Display product',
    quantity: 6,
    status: 'Restock',
  },
  {
    id: '9',
    image: 'Image9.jpg',
    name: 'Product I',
    client: 'Client Z',
    location: 'Location 3',
    type: 'Booth material',
    quantity: 18,
    status: 'In use',
  },
  {
    id: '10',
    image: 'Image10.jpg',
    name: 'Product J',
    client: 'Client X',
    location: 'Location 1',
    type: 'Display product',
    quantity: 7,
    status: 'Optimal stock',
  },
  {
    id: '11',
    image: 'Image11.jpg',
    name: 'Product K',
    client: 'Client Y',
    location: 'Location 2',
    type: 'Booth material',
    quantity: 14,
    status: 'In use',
  },
  {
    id: '12',
    image: 'Image12.jpg',
    name: 'Product L',
    client: 'Client Z',
    location: 'Location 3',
    type: 'Display product',
    quantity: 2,
    status: 'Depleted',
  },
  {
    id: '13',
    image: 'Image13.jpg',
    name: 'Product M',
    client: 'Client X',
    location: 'Location 1',
    type: 'Booth material',
    quantity: 9,
    status: 'Restock',
  },
  {
    id: '14',
    image: 'Image14.jpg',
    name: 'Product N',
    client: 'Client Y',
    location: 'Location 2',
    type: 'Display product',
    quantity: 4,
    status: 'Deactivated',
  },
  {
    id: '15',
    image: 'Image15.jpg',
    name: 'Product O',
    client: 'Client Z',
    location: 'Location 3',
    type: 'Booth material',
    quantity: 22,
    status: 'Optimal stock',
  },
];

export const ItemCategories = [
  {
    id: '1',
    name: 'Item A',
    subcategory: 'Category 1',
    item: 'car',
    status: 'In use',
  },
  {
    id: '2',
    name: 'Item B',
    subcategory: 'Category 2',
    item: 'bike',
    status: 'Optimal stock',
  },
  {
    id: '3',
    name: 'Item C',
    subcategory: 'Category 3',
    item: 'tv',
    status: 'Restock',
  },
];

export const itemSku = [
  {
    id: '1',
    image: 'Image1.jpg',
    name: 'Product A',
    client: 'Client X',
    location: 'Location 1',
    type: 'Booth material',
    quantity: 10,
    status: 'Optimal stock',
    category: 'exhibit components',
    subcategory: 'Furniture',
  },
  {
    id: '2',
    image: 'Image2.jpg',
    name: 'Product B',
    client: 'Client Y',
    location: 'Location 2',
    type: 'Display product',
    quantity: 5,
    status: 'In use',
    category: 'Collateral',
    subcategory: 'Furniture',
  },
  {
    id: '3',
    image: 'Image3.jpg',
    name: 'Product C',
    client: 'Client Z',
    location: 'Location 3',
    type: 'Booth material',
    quantity: 15,
    status: 'Restock',
    category: 'Portables',
    subcategory: 'Graphics',
  },
  {
    id: '4',
    image: 'Image4.jpg',
    name: 'Product D',
    client: 'Client X',
    location: 'Location 1',
    type: 'Display product',
    quantity: 8,
    status: 'Depleted',
    category: 'Collateral',
    subcategory: 'Giveaways',
  },
  {
    id: '5',
    image: 'Image5.jpg',
    name: 'Product E',
    client: 'Client Y',
    location: 'Location 2',
    type: 'Booth material',
    quantity: 20,
    status: 'In use',
    category: 'Fullfillment',
    subcategory: 'Supply kits',
  },
  {
    id: '6',
    image: 'Image6.jpg',
    name: 'Product F',
    client: 'Client Z',
    location: 'Location 3',
    type: 'Display product',
    quantity: 3,
    status: 'Deactivated',
    category: 'Exhibit Components',
    subcategory: 'Crates',
  },
  {
    id: '7',
    image: 'Image7.jpg',
    name: 'Product G',
    client: 'Client X',
    location: 'Location 1',
    type: 'Booth material',
    quantity: 12,
    status: 'Optimal stock',
  },
  {
    id: '8',
    image: 'Image8.jpg',
    name: 'Product H',
    client: 'Client Y',
    location: 'Location 2',
    type: 'Display product',
    quantity: 6,
    status: 'Restock',
  },
  {
    id: '9',
    image: 'Image9.jpg',
    name: 'Product I',
    client: 'Client Z',
    location: 'Location 3',
    type: 'Booth material',
    quantity: 18,
    status: 'In use',
  },
  {
    id: '10',
    image: 'Image10.jpg',
    name: 'Product J',
    client: 'Client X',
    location: 'Location 1',
    type: 'Display product',
    quantity: 7,
    status: 'Optimal stock',
  },
  {
    id: '11',
    image: 'Image11.jpg',
    name: 'Product K',
    client: 'Client Y',
    location: 'Location 2',
    type: 'Booth material',
    quantity: 14,
    status: 'In use',
  },
  {
    id: '12',
    image: 'Image12.jpg',
    name: 'Product L',
    client: 'Client Z',
    location: 'Location 3',
    type: 'Display product',
    quantity: 2,
    status: 'Depleted',
  },
  {
    id: '13',
    image: 'Image13.jpg',
    name: 'Product M',
    client: 'Client X',
    location: 'Location 1',
    type: 'Booth material',
    quantity: 9,
    status: 'Restock',
  },
  {
    id: '14',
    image: 'Image14.jpg',
    name: 'Product N',
    client: 'Client Y',
    location: 'Location 2',
    type: 'Display product',
    quantity: 4,
    status: 'Deactivated',
  },
  {
    id: '15',
    image: 'Image15.jpg',
    name: 'Product O',
    client: 'Client Z',
    location: 'Location 3',
    type: 'Booth material',
    quantity: 22,
    status: 'Optimal stock',
  },
];
