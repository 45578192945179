import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { OrderFormViewProps } from './OrderForm.props';
import PageLayout from 'components/layouts/PageLayout';
import Button from 'components/primitives/Button';
import Header from 'components/layouts/Header';
import { useNavigate } from 'react-router-dom';
import { Form } from 'formik';

import TabGeneralInfo from './tabs/TabGeneralInfo';
import TabItemsToShip from './tabs/TabItemsToShip';
import TabAccountInfo from './tabs/TabAccountInfo';
import { RoutePaths } from 'constants/routes';
import Loading from 'components/primitives/Loading';
import { ORDER_FORM_LOCAL_STORAGE_KEY } from 'utils/shipOrder';
import Toast from 'helperClasses/toastHelper';

const Tabs = {
  GENERAL_INFO: 'General Info',
  ITEMS_TO_SHIP: 'Items To Ship',
  ACCOUNT_INFO: 'Account Info',
};

type TabData = {
  name: string;
  component: ReactNode;
};

const OrderFormView: FC<OrderFormViewProps> = ({
  title,
  initialValues,
  onSubmit,
  mode = 'create',
  loading,
  saveForm,
  values,
  ...props
}) => {
  const navigate = useNavigate();
  const tabs: TabData[] = [
    { name: Tabs.GENERAL_INFO, component: <TabGeneralInfo mode={mode} /> },
    {
      name: Tabs.ITEMS_TO_SHIP,
      component: (
        <TabItemsToShip mode={mode} status={initialValues.orderStatus} />
      ),
    },
    { name: Tabs.ACCOUNT_INFO, component: <TabAccountInfo mode={mode} /> },
  ];
  useEffect(() => {
    saveForm && saveForm(values);
  }, [values, saveForm]);

  const handleCancel = useCallback(() => {
    localStorage.removeItem(ORDER_FORM_LOCAL_STORAGE_KEY);
    navigate(RoutePaths.shiporders);
  }, [saveForm]);

  const titleAsButton = () => {
    return (
      <button
        onClick={() => navigate('/ship-orders')}
        className="btn btn-ghost hover:bg-transparent focus:bg-transparent text-secondary">
        <span className="material-icons">keyboard_backspace</span> {title}
      </button>
    );
  };

  return (
    <>
      <Form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission on Enter key press
          }
        }}>
        <>
          <Header title={titleAsButton()}>
            <div className="flex-1 justify-end">
              <Button
                variant="outline"
                type="button"
                className="rounded-btn  border-blue-200 text-blue-200 btn-wide font-jakarta text-md normal-case"
                onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  const errors = await props.validateForm();
                  // props.handleErrorOnChangeTab(props.activeTabIndex, errors);
                  const hasErrors = Object.keys(errors).length > 0;
                  if (Object.keys(errors).length) {
                    Toast.error(
                      'Please fill all the required fields, in the previous step',
                    );
                  }
                }}
                isLoading={loading}
                type="submit"
                variant="solid"
                className="mx-2 rounded-btn border-blue-200 font-jakarta btn-wide text-md normal-case">
                {props.activeTabIndex < 2 ? (
                  'Continue'
                ) : (
                  <>
                    {mode === 'create' && 'CREATE TICKET'}
                    {mode === 'process' && 'PROCESS ORDER'}
                    {mode === 'ship' && 'MARK ORDER SHIPPED'}
                    {mode === 'edit' && 'Update'}
                  </>
                )}
              </Button>
            </div>
          </Header>
          <PageLayout className="items-center gap-5 mt-10">
            {!loading && (
              <div role="tablist" className="tabs tabs-bordered text-tertiary">
                {tabs.map((tab, index) => {
                  return (
                    <a
                      key={index}
                      role="tab"
                      //Disable the elememtn
                      className={`font-monsterrat  relative px-12 tab ${props.activeTabIndex === index ? 'tab-active text-primary-350 font-semibold' : 'text-primary-150'}`}
                      onClick={async () => {
                        const errors = await props.validateForm();
                        props.handleErrorOnChangeTab(index, errors);
                      }}>
                      {tab.name}
                      {props.validationCount[index] > 0 && (
                        <span className="absolute top-0 left-3 bg-red-500 w-5 h-5 rounded-xl text-xs text-white font-semibold">
                          {props.validationCount[index]}
                        </span>
                      )}
                    </a>
                  );
                })}
              </div>
            )}
            {/* Showing component here */}
            {tabs[props.activeTabIndex].component}
          </PageLayout>
        </>
      </Form>
    </>
  );
};

export default OrderFormView;
