import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { UserProfile } from 'types/user';
import { SortProps } from 'types/tables';
import { getUsers } from 'services/users';
import useDebounce from './useDebounce';

export const useUsers = ({
  page = 1,
  limit = 10,
  sort,
  role = '',
  designation,
}: {
  page?: number;
  limit?: number;
  sort?: SortProps<UserProfile>;
  role?: string;
  designation?: string;
} = {}) => {
  const [users, setUsers] = useState<UserProfile[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearch = useDebounce(searchValue, 1000);
  const {
    data,
    isLoading: loading,
    isSuccess,
  } = useQuery({
    queryKey: ['FETCH-USERS', page, debouncedSearch],
    queryFn: () =>
      getUsers({
        page,
        limit,
        sort,
        role,
        designation,
        search: searchValue !== '' ? debouncedSearch : undefined,
      }),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (isSuccess && data) {
      setUsers(data.items);
      setTotalPages(data.totalPages);
    }
  }, [isSuccess, data]);

  const sortDataBy = async (sort: SortProps<UserProfile>) => {
    if (sort) {
      const sortedItems = users.slice().sort((a, b) => {
        const factor = sort.order === 'asc' ? -1 : 1;
        const field = sort.field as keyof UserProfile;
        const A = a[field];
        const B = b[field];
        if (A < B) return factor * -1;
        if (A > B) return factor * 1;
        return 0;
      });
      setUsers(sortedItems);
    }
  };

  const checkAllElements = () => {
    setUsers((items) => {
      return items.map((item) => ({
        ...item,
        isChecked: !isAllChecked,
      }));
    });

    setIsAllChecked((prev) => !prev);
  };

  return {
    users,
    loading,
    sortDataBy,
    checkAllElements,
    totalPages,
    setSearchValue,
    searchValue,
  };
};
