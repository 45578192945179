import { OrderStatus } from 'types/shipOrders';
import * as Yup from 'yup';

import parsePhoneNumberFromString from 'libphonenumber-js';

export const defaultPackageObject = {
  size: {
    width: null,
    height: null,
    depth: null,
    unit: null,
  },
  weight: null,
};

export const defaultAccountInfoObject = {
  checkIn: null,
  deliveryAt: null,
  invoice: null,
  loading: null,
  misc: null,
  sac: null,
  shipping: null,
  total: null,
  totalBilled: null,
};

export const defaultClientInfo = {
  name: '',
  location: '',
  billingAddress: '',
  physicalAddress: '',
  contact: '',
  phone: '',
  email: '',
};
export const ORDER_FORM_LOCAL_STORAGE_KEY = 'orderFormLocalStorage';

export const initialValues = {
  accountInfo: defaultAccountInfoObject,
  orderStatus: OrderStatus.BEING_CREATED,
  accountNumber: null,
  address: {
    addressLine1: null,
    addressLine2: null,
    state: null,
    city: null,
    zip: null,
  },
  package: defaultPackageObject,
  client: '',
  arrivalDate: '',
  booth: '',
  event: '',
  isClientBillToAccount: true,
  notes: '',
  orderDetail: [],
  pickupDate: '',
  prepByDate: '',
  projectManager: '',
  returnDate: undefined,
  shipDate: '',
  advanceWarehouseDate: {
    startDate: null,
    endDate: null,
  },
  isReturnable: true,
  shippingPreference: '',
  warehouse: process!.env!.REACT_APP_WAREHOUSE_ID
    ? process!.env!.REACT_APP_WAREHOUSE_ID
    : '',
  clientInfo: defaultClientInfo,
  contact: {
    contactName: '',
    phoneNumber: '',
  },
  recipient: {
    name: '',
    phone: '',
    email: '',
  },
};

export const generateInfoValidations = Yup.object().shape({
  orderStatus: Yup.number(),
  booth: Yup.string().nullable(),
  event: Yup.string().required('Event is required'),
  shippingPreference: Yup.string().required('Shipping Preference is required'),
  isClientBillToAccount: Yup.boolean().required('Bill To Account is required'),
  prepByDate: Yup.date().nullable(),
  accountNumber: Yup.string().nullable(),
  shipDate: Yup.date().when('orderStatus', {
    is: (orderStatus: number) => orderStatus === OrderStatus.CREATED,
    then: (schema) => schema.required('Ship Date is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  arrivalDate: Yup.date().required('Arrival Date is required'),
  pickupDate: Yup.date().required('Pickup Date is required'),
  returnDate: Yup.date().nullable(),
  projectManager: Yup.string().required('Project Manager is required'),
  address: Yup.object().shape({
    addressLine1: Yup.string().required('Delivery Address is required'),
    addressLine2: Yup.string().nullable(),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    zip: Yup.string()
      .matches(/^\d{5}$/, 'Zip code must be 5 digits')
      .required('Zip code is required'),
  }),
  package: Yup.object().shape({
    size: Yup.object().shape({
      width: Yup.number().when('$orderStatus', {
        is: (orderStatus: number) => orderStatus !== OrderStatus.BEING_CREATED,
        then: (schema) => schema.required('Width is required'),
        otherwise: (schema) => schema.nullable(),
      }),
      height: Yup.number().when('$orderStatus', {
        is: (orderStatus: number) => orderStatus !== OrderStatus.BEING_CREATED,
        then: (schema) => schema.required('Height is required'),
        otherwise: (schema) => schema.nullable(),
      }),
      depth: Yup.number().when('$orderStatus', {
        is: (orderStatus: number) => orderStatus !== OrderStatus.BEING_CREATED,
        then: (schema) => schema.required('Depth is required'),
        otherwise: (schema) => schema.nullable(),
      }),
      unit: Yup.string().when('$orderStatus', {
        is: (orderStatus: number) => orderStatus !== OrderStatus.BEING_CREATED,
        then: (schema) => schema.required('Unit is required'),
        otherwise: (schema) => schema.nullable(),
      }),
    }),
    weight: Yup.number().when('$orderStatus', {
      is: (orderStatus: number) => orderStatus !== OrderStatus.BEING_CREATED,
      then: (schema) => schema.required('Weight is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  }),
  advanceWarehouseDate: Yup.object({
    startDate: Yup.date().nullable(),
    endDate: Yup.date()
      .nullable()
      .when('startDate', {
        is: (startDate: Date) => !!startDate,
        then: (schema) => schema.required('End Date is required'),
      }),
  }).nullable(),
  isReturnable: Yup.boolean().required('Returnable is required'),
  notes: Yup.string(),
  client: Yup.string().required('Client is required'),
  transactionNumber: Yup.string().when('orderStatus', {
    is: (orderStatus: number) => orderStatus === OrderStatus.CREATED,
    then: (schema) => schema.required('Transaction Number is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  contact: Yup.object()
    .shape({
      contactName: Yup.string().optional().nullable(),
      phoneNumber: Yup.string().optional().nullable(),
    })
    .nullable(),
  recipient: Yup.object()
    .shape({
      name: Yup.string().optional(),
      email: Yup.string().email('Invalid email').optional(),
      phone: Yup.string()
        .required('Phone number is required')
        .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
    })
    .optional(),
});

export const accountInfoValidations = Yup.object().shape({
  accountInfo: Yup.object().shape({
    checkIn: Yup.number().nullable(),
    deliveryAt: Yup.date().nullable(),
    invoice: Yup.string().nullable(),
    loading: Yup.number().nullable(),
    misc: Yup.number().nullable(),
    sac: Yup.number().nullable(),
    shipping: Yup.number().nullable(),
    total: Yup.number().nullable(),
    totalBilled: Yup.number().nullable(),
  }),
});

export const orderDetailValidations = Yup.object().shape({
  orderDetail: Yup.array()
    .min(1, 'Atleast 1 item is needed to create an order')
    .required('Atleast 1 item is needed to create an order'),
});
const packageValidationSchema = Yup.object().shape({
  size: Yup.object().shape({
    width: Yup.number().required('Width is required'),
    height: Yup.number().required('Height is required'),
    depth: Yup.number().required('Depth is required'),
    unit: Yup.string().required('Unit is required'),
  }),
  weight: Yup.number().required('Weight is required'),
});

export const warehouseValidationWithPackage = Yup.object().shape({
  ...generateInfoValidations.fields,
  package: packageValidationSchema,
  contact: Yup.object()
    .shape({
      contactName: Yup.string().optional(),
      phoneNumber: Yup.string().optional(),
    })
    .nullable(),
});
// export const validationSchema = Yup.object().shape({
//   OrderStatus: Yup.number(),
//   booth: Yup.string().required('Booth is required'),
//   event: Yup.string().required('Event is required'),
//   shippingPreference: Yup.string().required('Shipping Preference is required'),
//   isClientBillToAccount: Yup.boolean().required('Bill To Account is required'),
//   prepByDate: Yup.date().required('Prep By Date is required'),
//   accountNumber: Yup.number().required('Account Number is required'),
//   shipDate: Yup.date().required('Ship Date is required'),
//   arrivalDate: Yup.date().required('Arrival Date is required'),
//   pickupDate: Yup.date().required('Pickup Date is required'),
//   returnDate: Yup.date().required('Return Date is required'),
//   projectManager: Yup.string().required('Project Manager is required'),
//   address: Yup.object().shape({
//     addressLine1: Yup.string().required('Delivery Address is required'),
//     addressLine2: Yup.string().required('Apartment is required'),
//     state: Yup.string().required('State is required'),
//     city: Yup.string().required('City is required'),
//     zip: Yup.string()
//       .matches(/^\d{5}$/, 'Zip code must be 5 digits')
//       .required('Zip code is required'),
//   }),
//   package: Yup.object().shape({
//     size: Yup.object().shape({
//       width: Yup.number().required('Width is required'),
//       height: Yup.number().required('Height is required'),
//       depth: Yup.number().required('Depth is required'),
//       unit: Yup.string().required('Unit is required'),
//     }),
//     weight: Yup.number().required('Weight is required'),
//   }),
//   advanceWarehouseDate: Yup.object({
//     startDate: Yup.date().required('Start Date is required'),
//     endDate: Yup.date()
//       .required('End Date is required')
//       .min(Yup.ref('startDate'), 'End Date must be after Start Date'),
//   }),
//   notes: Yup.string(),
//   client: Yup.string().required('Client is required'),

//   accountInfo: Yup.object().shape({
//     checkIn: Yup.number().required('Receive/Check-in is required'),
//     deliveryAt: Yup.date().required('Date is required'),
//     invoice: Yup.string().required('Invoice is required'),
//     loading: Yup.number().required('Handling/Loading is required'),
//     misc: Yup.number().required('Repairs And Misc is required'),
//     sac: Yup.number().required('SAC is required'),
//     shipping: Yup.number().required('Shipping is required'),
//     total: Yup.number().required('Total is required'),
//     totalBilled: Yup.number(),
//   }),
//   orderDetail: Yup.array()
//     .min(1, 'Atleast 1 item is needed to create an order')
//     .required('Atleast 1 item is needed to create an order'),
// });
