import React, { FC, ReactNode } from 'react';

import { HeaderViewProps } from './Header.props';
import { useLocation } from 'react-router-dom';

import HeaderView from './Header.view';
import { NavItems } from '../../../constants/sideBarMenuItems';

const HeaderContainer: React.FC<HeaderViewProps> = ({ children, title }) => {
  const location = useLocation();
  const defaultTitle =
    title || NavItems.find((item) => item.path === location.pathname)?.title;

  return <HeaderView title={defaultTitle}>{children}</HeaderView>;
};

export default HeaderContainer;
