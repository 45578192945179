const Info = ({ fill = '#2993C6' }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 5.5V8M10.5 6C10.5 8.48528 8.48528 10.5 6 10.5C3.51472 10.5 1.5 8.48528 1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C8.48528 1.5 10.5 3.51472 10.5 6Z"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="6" cy="3.75" r="0.5" fill={fill} />
    </svg>
  );
};

export default Info;
