import ItemsToShip from 'components/modules/ItemsToShip';
import React, { FC } from 'react';
import { TabItemsToShipProps } from '../OrderForm.props';

const TabItemsToShip: FC<TabItemsToShipProps> = (props) => {
  return (
    <div className="container mx-auto  py-2 px-5">
      <ItemsToShip {...props} />
    </div>
  );
};

export default TabItemsToShip;
