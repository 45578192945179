import React from 'react';
import { IconProps } from './iconProps';

const DashboardIcon = ({ w = '12', h = '12', className = '' }: IconProps) => {
  return (
    <svg width={w} height={h} className={className} viewBox="0 0 12 12">
      <path
        stroke="none"
        d="M7.333 4a.645.645 0 01-.475-.192.645.645 0 01-.191-.475V.667c0-.19.064-.348.191-.475A.645.645 0 017.333 0h4c.19 0 .348.064.475.192A.645.645 0 0112 .667v2.666a.645.645 0 01-.192.475.645.645 0 01-.475.192h-4zM.667 6.667a.645.645 0 01-.475-.192A.645.645 0 010 6V.667C0 .477.064.319.192.192A.645.645 0 01.667 0h4c.189 0 .347.064.475.192a.645.645 0 01.191.475V6a.645.645 0 01-.191.475.645.645 0 01-.475.192h-4zM7.333 12a.645.645 0 01-.475-.192.645.645 0 01-.191-.475V6c0-.189.064-.347.191-.475a.645.645 0 01.475-.192h4c.19 0 .348.064.475.192A.645.645 0 0112 6v5.333a.645.645 0 01-.192.475.645.645 0 01-.475.192h-4zM.667 12a.645.645 0 01-.475-.192.645.645 0 01-.192-.475V8.667c0-.19.064-.348.192-.475A.645.645 0 01.667 8h4c.189 0 .347.064.475.192a.645.645 0 01.191.475v2.666a.645.645 0 01-.191.475.645.645 0 01-.475.192h-4z"></path>
    </svg>
  );
};

export default DashboardIcon;
