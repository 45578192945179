import axios from 'axios';

import { $session } from 'stores';

import { LoginResponse } from 'types/auth';

import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { $site } from 'stores/site';
import Toast from 'helperClasses/toastHelper';

const URL = `${process.env.REACT_APP_API_URL}`;
const STORAGE_URL = `${process.env.REACT_APP_STORAGE_URL}`;

export const getApiURL = () => {
  return URL;
};

export const getStorageURL = () => {
  return STORAGE_URL;
};

const DEFAULT_HEADERS: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'ngrok-skip-browser-warning': 'true',
};

const instance = axios.create({
  baseURL: URL,
  headers: DEFAULT_HEADERS,
});

instance.interceptors.request.use((request) => {
  const tokens = $session.actions.getTokens();
  const jwtToken = tokens?.accessToken || '';
  if (jwtToken) {
    request.headers.Authorization = `Bearer ${jwtToken}`;
  }
  request.headers['x-tenant-id'] = $site.actions.getTenant();
  request.headers['x-requestor-id'] = `${process.env.REACT_APP_X_REQUESTER_ID}`;
  return request;
});

const refreshAuthToken = async (payload: {
  accessToken: string;
  refreshToken: string;
}) => {
  const { data } = await axios.post(`${URL}/auth/refresh-token`, payload, {
    headers: {
      'x-tenant-id': $site.actions.getTenant(),
      'x-requestor-id': process.env.REACT_APP_X_REQUESTER_ID,
    },
  });
  return data as LoginResponse;
};

const refreshTokenLogic = async (callbackRequest: any) => {
  try {
    if (callbackRequest.response.data.error.code === 10003) {
      const tokens = $session.actions.getTokens();

      if (!tokens) {
        return Promise.reject({
          response: {
            data: {
              message: 'Please Log in',
            },
          },
        });
      }

      const refreshToken = tokens.refreshToken;
      const accessToken = tokens.accessToken;

      const data = await refreshAuthToken({
        accessToken,
        refreshToken,
      })
        .then((data) => data.data)
        .catch(() => null);

      if (!data) {
        Toast.error('Your session has expired. Please Log in again.');
        setTimeout(() => {
          $session.actions.clearSession();
          window.location.href = '/login';
        }, 2000);
        return;
      }

      $session.actions.setSession({
        tokens: {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          expiresIn: data.expiresIn,
        },
        email: data.email,
      });

      callbackRequest.response.config.headers['Authorization'] =
        `Bearer ${data.accessToken}`;
      callbackRequest.response.config.headers['x-tenant-id'] =
        $site.actions.getTenant();
      callbackRequest.response.config.headers['x-requestor-id'] =
        `${process.env.REACT_APP_X_REQUESTER_ID}`;
      return Promise.resolve();
    } else {
      $session.actions.clearSession();
      return Promise.resolve();
    }
  } catch (error: any) {}
};

const options = { statusCodes: [401] };

createAuthRefreshInterceptor(instance, refreshTokenLogic, options);

export const api = instance;
