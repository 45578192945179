import { useQuery } from '@tanstack/react-query';
import { fetchShipOrderById } from 'services/shipOrders';

export const useShipOrderDetail = (id: string) => {
  const { data: shipOrderDetail, isLoading: loading } = useQuery({
    queryKey: ['FETCH-SHIP-ORDER-DETAILS', id],
    queryFn: () => fetchShipOrderById(id),
    staleTime: 0,
  });

  return { shipOrderDetail, loading };
};
