import { ApiResponseList } from 'types/common';
import { SortProps } from 'types/tables';
import { api } from './instance';
import { UserProfile } from 'types/user';

export const getUsers = async (params?: {
  page?: number;
  limit?: number;
  sort?: SortProps<UserProfile>;
  role?: string;
  search?: string;
  designation?: string;
}) => {
  try {
    const { data } = await api.get<ApiResponseList<UserProfile>>('/user/list', {
      params,
    });
    return data.data;
  } catch (error) {
    console.log(error);
  }
};
