import { toast } from 'react-toastify';

interface ToastErrorProps {
  errorMessage: string;
}

export const ToastError = ({ errorMessage }: ToastErrorProps) => {
  console.log('errorMessage', errorMessage);
  toast.error(errorMessage, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    icon: false,
  });
};
